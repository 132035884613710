<p-toast [styleClass]="'toast'" key="notifications" position="top-right" [baseZIndex]="5000">
  <ng-template let-notification pTemplate="notification">
    <app-notification [actions]="notification.data?.actions" [message]="notification.detail" [severity]="notification.severity" [summary]="notification.summary"></app-notification>
  </ng-template>
</p-toast>


<div class="router-wrapper">
  <router-outlet></router-outlet>
</div>

<!-- FOOTER -->