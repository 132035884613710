import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { DialogService } from 'primeng/dynamicdialog';
import { AppUpdatesService } from './shared/services/app-updates.service';
import { PrimeNGConfig } from 'primeng/api';
import { BreadcrumbsService, IPathArray } from './shared/services/breadcrumbs.service';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { AuthService } from './shared/models/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { Platform } from '@angular/cdk/platform';
import { Loader } from "@googlemaps/js-api-loader"
import { StateService } from './shared/services/state.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastModule, NavigationComponent, NotificationComponent
  ],
  providers: [
    
    DialogService,
    AppUpdatesService
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  message: any = null;


  public home: IPathArray = {
    label: 'Home',
    routerLink: '/',
  }
  constructor(private primengConfig: PrimeNGConfig, public breadcrumbsService: BreadcrumbsService,
    private appUpdateService: AppUpdatesService, private authService: AuthService, private url: LocationStrategy, private router: Router,
  private platform: Platform, private stateService: StateService){


      this.primengConfig.ripple = true; 
    // Check for new versions and update Service worker:
    this.appUpdateService.performAppUpdates();
    

    if (this.authService.getRefreshToken() !== null && !this.authService.checkIfTokenExpired()) {
      this.authService.refreshTokenStatic(this.authService.getRefreshToken()).subscribe({
        next: (token: any) => {
        //  this.authService.setToken(token.token.accessToken);
        this.authService.setToken(token.token);
        this.authService.setRefreshToken(token.refreshToken)
        },
        error: (error: HttpErrorResponse) => {
          this.authService.logout();
        },
        complete: () => {
          if (this.url.path() == '/auth/login' || this.url.path() == '/auth/register') {
            this.router.navigate(['/admin/home']);
          };
        }
      });
    }

    if (this.url.path() == '/') {
      this.router.navigate(['/auth/login']);
    };


  }


  ngOnInit(): void {

  }




  private loadGoogleMaps(){
    const loader = new Loader({
      apiKey: '',
      version: "weekly",
    });
    
    loader.importLibrary('maps')
  }
}
