import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { ActionReducerMap, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { mainInterceptor } from './shared/http/main-interceptor';
import { MessageService } from 'primeng/api';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { environment } from '../environments/environment';


// Configure scroll behavior.
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top', // Set to 'top' to always scroll back to the top.
  anchorScrolling: 'enabled', // Enable anchor scrolling if needed.
};

// Create an in-memory scrolling feature.
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

const reducers: ActionReducerMap<any> = {

}

export const appConfig: ApplicationConfig = {
  providers: [
              provideRouter(routes, inMemoryScrollingFeature),
              provideAnimations(),
              provideStore(reducers),
              provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
              provideMessaging(() => getMessaging()),
              provideEffects([]),
              provideRouterStore(),
              provideHttpClient(withFetch(), withInterceptors([mainInterceptor])), 
              MessageService,
              provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
              provideServiceWorker('ngsw-worker.js', {
                  enabled: !isDevMode(),
                  registrationStrategy: 'registerWhenStable:30000'
    }), provideAnimationsAsync()
  ]
};
