import { Injectable, afterNextRender, PLATFORM_ID, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { MessageService } from 'primeng/api';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { BUILD } from '../../../environments/build';
@Injectable({
  providedIn: 'root',
})
export class AppUpdatesService {
 
  private platformId: {};
  constructor(
    private swUpdate: SwUpdate,
    private messageService: MessageService,
   ) {    
    this.platformId = inject(PLATFORM_ID);
  }


  private performSmallScroll(){
    window.scrollTo(0, 1);
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 0);
  }

  performAppUpdates() {

    afterNextRender(() => {
   

        if (environment.staging || environment.production) {
          const buildId = BUILD;
          const lastBuildId = localStorage.getItem('versionId');
          console.log('current build', buildId);
          console.log('last build', lastBuildId);
          localStorage.setItem('versionId', BUILD);
          console.log('SERVICE WORKER');
          this.swUpdate.versionUpdates
            .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
            .subscribe(evt => {
              console.log('FOUND A NEW VERSION, THIS WONT RELOAD, NEW FUNCTION TO TEST 0', evt);
            });
  
          this.swUpdate.checkForUpdate().then((response) => {
            console.log('UPDATE FOUND : ', response);
            if (response) {
              console.log('New app version ready for use 1');
              console.log('Will reload in 5 seconds in dev and 0 in prod....');
              if (environment.staging) {
                if(isPlatformBrowser(this.platformId)){
                  this.messageService.add({ severity: 'info', key:'notifications', summary: 'Website update', detail: 'New web version is ready for use, reloading (in 5s dev, 0s prod)...' });
                  this.performSmallScroll();
                }
              }
              const seconds = environment.staging ? 5000 : 0;
              setTimeout(() => {
                document.location.reload();
              }, seconds)
            }
            this.swUpdate.versionUpdates.subscribe(evt => {
              switch (evt.type) {
                case 'VERSION_DETECTED':
                  console.log(`Downloading new app version: ${evt.version.hash}`);
                  break;
                case 'VERSION_READY':
                  console.log(`Current app version: ${evt.currentVersion.hash}`);
                  console.log(`New app version ready for use 2: ${evt.latestVersion.hash}`);
                  console.log('Will reload in 5 seconds in dev and 0 in prod....');
                  if (environment.staging) {
                    if(isPlatformBrowser(this.platformId)){
                      this.messageService.add({ severity: 'info', key: 'notifications', summary: 'Website update', detail: 'New web version is ready for use, reloading (in 5s dev, 0s prod)...' });
                      this.performSmallScroll();
                    }

                  }
                  const seconds = environment.staging ? 5000 : 0;
                  setTimeout(() => {
                    document.location.reload();
                  }, seconds)
                  break;
                case 'VERSION_INSTALLATION_FAILED':
                  if (environment.staging) {
                    if(isPlatformBrowser(this.platformId)){
                      this.messageService.add({ severity: 'error', key: 'notifications', summary: 'Website update', detail: `Failed to install app version '${evt.version.hash}': ${evt.error}` });
                      this.performSmallScroll();
                    }
  
                  }
                  console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                  break;
              }
            });
  
            this.swUpdate.unrecoverable.subscribe(event => {
              console.log('An error occurred that we cannot recover from:\n' + ' ' + event.reason + ' ' + '\n\nPlease reload the page.');
              const seconds = environment.staging ? 5000 : 0;
              setTimeout(() => {
                document.location.reload();
              }, seconds)
            });
  
          });
        }
      
    })

  }
}
