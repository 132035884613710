import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
// import { MessageService } from 'primeng/api';
import { inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { AuthService } from '../models/auth/auth.service';
import { Router } from '@angular/router';
import { ThreeShapeOauthService } from '../services/integrations/three-shape-oauth.service';

export var isRefreshing: boolean = false;
export const httpVariables = {
  BASE_API_ORIGIN: environment.apiOrigin,
  BASE_API_PATH: environment.apiPath,
  AUTH_TOKEN: 'auth-token',
  HEADER_AUTHORIZATION: 'Authorization',
  HEADER_ACCEPT: 'Accept',
  HEADER_CONTENT_TYPE: 'Content-Type',
  ACCEPT_LANGUAGE: 'Accept-Language',
  LANGUAGE: 'X-LANGUAGE',
  CLIENT: 'X-CLIENT',
};

export function refreshToken(
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  messageService: MessageService,
  platformId: {},
  authService: AuthService,
  router: Router
): Observable<HttpEvent<unknown>> {
  isRefreshing = true;
  return authService.refreshTokenStatic(authService.getRefreshToken()).pipe(
    switchMap((token: any) => {
      isRefreshing = false;
      // authService.setToken(token.token.accessToken);
      authService.setToken(token.token);
      // SET NEW REFRESH TOKEN
      authService.setRefreshToken(token.refreshToken);
      const newToken = authService.getToken();
      const transformedReq = req.clone({
        headers: req.headers.set(
          httpVariables.HEADER_AUTHORIZATION,
          `Bearer ${newToken}`
        ),
      });
      return next(transformedReq);
    }),
    catchError((error: HttpErrorResponse) => {
      isRefreshing = false;
      authService.logout();
      router.navigate(['auth/login']);
      return handleHttpError(messageService, platformId, error, authService); // Return the observable returned by handleHttpError
    })
  );
}


export function refreshThreeShapeToken(
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  messageService: MessageService,
  platformId: {},
  threeShapeOauthService: ThreeShapeOauthService,
  router: Router
): Observable<HttpEvent<unknown>> {
  isRefreshing = true;
  return threeShapeOauthService.refreshTokenStatic(threeShapeOauthService.getRefreshToken()).pipe(
    switchMap((token: any) => {
      isRefreshing = false;
      // authService.setToken(token.token.accessToken);
      threeShapeOauthService.setToken(token.token);
      // SET NEW REFRESH TOKEN
      threeShapeOauthService.setRefreshToken(token.refreshToken);
      const newToken = threeShapeOauthService.getToken();
      const transformedReq = req.clone({
        headers: req.headers.set(
          httpVariables.HEADER_AUTHORIZATION,
          `Bearer ${newToken}`
        ),
      });
      return next(transformedReq);
    }),
    catchError((error: HttpErrorResponse) => {
      isRefreshing = false;
      threeShapeOauthService.logout();
      router.navigate(['auth/login']);
      return handleHttpError(messageService, platformId, error, threeShapeOauthService); // Return the observable returned by handleHttpError
    })
  );
}



export function mainInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const platformId = inject(PLATFORM_ID);
  const messageService = inject(MessageService);
  const authService = inject(AuthService);
  const threeShapeOauthService = inject(ThreeShapeOauthService);
  const router = inject(Router);


  if (req.headers.get('Content-type')) {
    return next(req);
  }
  if (req.headers.get('skip')) {
    return next(req);
  }

  
  if (req.url.startsWith('./assets') || req.url.startsWith('/assets')) {
    return next(req);
  }

  req = req.clone({
    url: _prefixUrl(req.url),
  });

  req = req.clone({
    headers: req.headers.set('Cache-Control', 'no-cache'),
  });

  if(req.headers.get('threeShape')){
    req = req.clone({
      headers: req.headers.set(httpVariables.HEADER_AUTHORIZATION, `Bearer ${threeShapeOauthService.getToken()}`),
    });
  } else {
    req = req.clone({
      headers: req.headers.set(httpVariables.HEADER_AUTHORIZATION, `Bearer ${authService.getToken()}`),
    });
  }


  const tokenExpired = !authService.checkIfTokenExpired();

  if (!req.headers.get('skipRefreshToken')) {
    if(req.headers.get('threeShape')){
      if (tokenExpired && threeShapeOauthService.getToken()) {
        if (!isRefreshing) {
          return refreshToken(req, next, messageService, platformId, authService, router);
        } else {
          return EMPTY;
        }
      } else {
        if (req.headers.get('token')) {
          const token = authService.getToken();
          if (token) {
            req = req.clone({
              headers: req.headers.set(
                httpVariables.HEADER_AUTHORIZATION,
                `Bearer ${token}`
              ),
            });
          }
        }
        return next(req).pipe(
          tap((event) => {
            if (event.type === HttpEventType.Response) {
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return handleHttpError(
              messageService,
              platformId,
              error,
              authService
            ); // Return the observable returned by handleHttpError
          })
        );
      }
    } else {
      if (tokenExpired && authService.getToken()) {
        if (!isRefreshing) {
          return refreshToken(req, next, messageService, platformId, authService, router);
        } else {
          return EMPTY;
        }
      } else {
        if (req.headers.get('token')) {
          const token = authService.getToken();
          if (token) {
            req = req.clone({
              headers: req.headers.set(
                httpVariables.HEADER_AUTHORIZATION,
                `Bearer ${token}`
              ),
            });
          }
        }
        return next(req).pipe(
          tap((event) => {
            if (event.type === HttpEventType.Response) {
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return handleHttpError(
              messageService,
              platformId,
              error,
              authService
            ); // Return the observable returned by handleHttpError
          })
        );
      }
    }
  } else {
    if (req.headers.get('token') && !req.headers.get('threeShape')) {
      const token = authService.getToken();
      if (token) {
        req = req.clone({
          headers: req.headers.set(
            httpVariables.HEADER_AUTHORIZATION,
            `Bearer ${token}`
          ),
        });
      }
    } else {
      const token = threeShapeOauthService.getToken();
      if (token) {
        req = req.clone({
          headers: req.headers.set(
            httpVariables.HEADER_AUTHORIZATION,
            `Bearer ${token}`
          ),
        });
      }
    }
    return next(req).pipe(
      tap((event) => {
        // DO something with the response
      }),
      catchError((error: HttpErrorResponse) => {
        return handleHttpError(messageService, platformId, error, authService); // Return the observable returned by handleHttpError
      })
    );
  }
}

export function handleHttpError(
  messageService: MessageService,
  platformId: {},
  httpErrorResponse: HttpErrorResponse,
  authService: AuthService | ThreeShapeOauthService
): Observable<HttpEvent<unknown>> {

  if (httpErrorResponse.url?.endsWith('/upload-file')) {
    displayErrorNotification(
      messageService,
      platformId,
      httpErrorResponse,
      'File upload error, file is too large.'
    );
  } else if (httpErrorResponse.status === 0) {
    displayErrorNotification(
      messageService,
      platformId,
      httpErrorResponse,
      'Could not connect to server!'
    );
  } else if (httpErrorResponse.status === 401) {
    displayErrorNotification(
      messageService,
      platformId,
      httpErrorResponse,
      httpErrorResponse.error.message
    );
    authService?.logout();
  } else if (httpErrorResponse.status === 403) {
    displayErrorNotification(
      messageService,
      platformId,
      httpErrorResponse,
      'Your account is not active, please contact support info@qualylab.lt'
    );
    authService?.logout();
  } else if (httpErrorResponse.status === 413) {
    displayErrorNotification(
      messageService,
      platformId,
      httpErrorResponse,
      'One of the selected files are too large.'
    );
  } else if(httpErrorResponse.status === 500){
    displayErrorNotification(
      messageService,
      platformId,
      httpErrorResponse,
      'Internal server error!'
    );
  } else {
    displayErrorNotification(messageService, platformId, httpErrorResponse);
  }

  return throwError(httpErrorResponse);
}

export function displayErrorNotification(
  messageService: MessageService,
  platformId: {},
  httpErrorResponse?: HttpErrorResponse,
  customMessage?: string
): void {
  if (isPlatformBrowser(platformId)) {
    if (customMessage) {
      messageService.add({
        severity: 'error',
        key: 'notifications',
        detail: customMessage,
      });
    } else if (httpErrorResponse) {
      if(httpErrorResponse.error.fieldErrors){
        for (const fieldError of httpErrorResponse.error.fieldErrors) {
          messageService.add({
            severity: 'error',
            key: 'notifications',
            detail:  `${fieldError.field} : ${fieldError.message}`
          });
        }
      } else {
        messageService.add({
          severity: 'error',
          key: 'notifications',
          detail: 
            httpErrorResponse.error.message ||
            httpErrorResponse.error.error ||
            httpErrorResponse.error.detail,
        });
      }

    }
  }
}

export function _prefixUrl(path: string): string {
  if (path.indexOf('/') === 0) {
    path = path.substr(1, path.length - 1);
  }

  return `${httpVariables.BASE_API_ORIGIN}/${httpVariables.BASE_API_PATH}/${path}`;
}
